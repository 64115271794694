.carouselImg {
    animation-name: FadeIn;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}

@keyframes FadeIn {
    from {
        opacity: 0.0;
    }
    to {
        opacity: 1.0;
    }
}